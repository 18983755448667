import * as React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import EarlyAccess from '../components/EarlyAccess'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'
import { StaticImage } from "gatsby-plugin-image"

const Homepage = () => (
	<Layout isHomepage>
		<Seo title="Home" />
		<Container>
			<div style={{ minHeight: '80vh', display: "flex", flexDirection: 'row', paddingTop: '4em' }}>
				<div className="my-auto">
					<Row>
						<Col lg={7} className='pb-4'>
							<div style={{ height: '4em' }} className='d-block d-lg-none'>

							</div>
							<h1 className='hero-text'>
								Don't judge a book by its cover, take a look at excerpts
							</h1>
							<p className='pt-4'>Excerpts is a community of intellects sharing their inspirations. Share excerpts, quotes, follow your favorite readers and discover new books.</p>
							<EarlyAccess />
						</Col>
						<Col lg={5}>
							<div style={{ height: '7em' }} className='d-block d-lg-none'>

							</div>
							<StaticImage src="../images/screenshot.png" alt="Excerpt Screenshot" placeholder='blurred' className='mb-4' />
							<div style={{ height: '4em' }} className='d-block d-lg-none'>

							</div>
						</Col>
					</Row>
				</div>
			</div>
		</Container>
	</Layout>
)

export default Homepage