import React, { useRef, useState } from 'react'
import { Button } from 'react-bootstrap';
import { navigate } from 'gatsby';

export default function EarlyAccess() {
    const [email, setEmail] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const formRef = useRef();

    function submitForm() {
        try {
            if (loading) return;
            const v = formRef.current.checkValidity();
            if (v) {
                setLoading(true);
                formRef.current.submit();
            }
            else {

            }
        }
        catch (err) {
            console.log(err)
        }
    }

    function redirect() {
        try {
            if (!loading) return;
            const v = formRef.current.checkValidity();
            if (v) {
                navigate('/thankyou')
            }
            else {

            }
        }
        catch (err) {
            console.log(err)
        }
    }


    return (
        <div>
            <h3 className='mt-4'>Get early access,</h3>

            <form

            >

                <input className='mt-2' onChange={(e) => setEmail(e.target.value)} type="email" name="email" placeholder='Email' value={email} />
                

                <Button style={{ opacity: loading ? "0.5" : "1" }} onClick={() => submitForm()}>Submit</Button>
            </form>

            <form
                style={{ display: 'none' }}
                ref={formRef}
                action="https://formspree.io/f/mayvojkv"
                method="POST"
                target="dummyframe"
            >
                <input type="email" name="email" placeholder='Email' value={email} required />
                <button type="submit">Send</button>

            </form>
            <iframe onLoad={(e) => redirect()} name="dummyframe" id="dummyframe" className='d-none' ></iframe>

        </div>
    )
}
